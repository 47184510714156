import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import MetaData from "../components/meta"

// Import Styles
import "../styles/careers.scss"

// Import Images
import values__openness from "../images/careers/openness-icon.svg"
import values__trust from "../images/careers/trust-icon.svg"
import values__excellence from "../images/careers/excellence-icon.svg"
import values__humility from "../images/careers/humility-icon.svg"
import job__it from "../images/careers/job-types/job-it.svg"
import job__marketing from "../images/careers/job-types/job-marketing.svg"
import job__sales from "../images/careers/job-types/job-sales.svg"

const CareersPage = ({ data, location }) => {
  const job_posts = data.job_posts.edges

  const getJobIcon = (job_category: string) => {
    switch (job_category) {
      case "job-it":
        return job__it
      case "job-marketing":
        return job__marketing
      case "job-sales":
        return job__sales
      default:
        return job__sales
    }
  }

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />
      <div className="careers">
        {/* Header */}
        <section className="careers__header">
          <div className="wrapper-1200">
            <h1>Join our team</h1>
            <p>
              We’re creating networking solutions that application teams love to
              use. Help us on our journey to build the future of networking!
            </p>
            <button
              type="button"
              onClick={() => scrollTo(".careers__posts")}
              className="button--primary button--extra-padding"
            >
              See Open Positions
            </button>
          </div>
        </section>

        {/* Values */}
        <section className="careers__values">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>Our Values</h2>
            <div className="values">
              <div className="value">
                <img src={values__openness} />
                <h4>Openness</h4>
                <p>
                  A way of living, working, building the future together, being
                  open to many possibilities.
                </p>
              </div>
              <div className="value">
                <img src={values__trust} />
                <h4>Trust</h4>
                <p>
                  Trusted relationships, confidence and reliability to build a
                  better company.
                </p>
              </div>
              <div className="value">
                <img src={values__excellence} />
                <h4>Excellence</h4>
                <p>Being the best, and delivering the best as a team.</p>
              </div>
              <div className="value">
                <img src={values__humility} />
                <h4>Humility</h4>
                <p>
                  Being humble and respectful with everyone; there is much to
                  learn from others.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Map */}
        <section className="careers__map">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>We are remote friendly!</h2>
            <p>Our teams are all over the globe</p>
            <div className="map">
              <GatsbyImage
                image={data.map_image.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
        </section>

        {/* Job Posts */}
        <section id="open-positions" className="careers__posts">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>Open Positions</h2>
            <p className="sub-title">
              See something that interests you? Apply today.
            </p>
            <div className="jobs">
              {/* Job Posts Cards */}
              {job_posts.map(({ node }) => (
                <div className="job" key={node.id}>
                  <img
                    src={getJobIcon(node.tags[1]?.slug)}
                    alt={node.title}
                    className="type"
                  />

                  <h4>{node.title}</h4>

                  <div
                    className="job-card__highlights"
                    dangerouslySetInnerHTML={{
                      __html: node.childHtmlRehype.html,
                    }}
                  ></div>

                  <Link
                    to={`/careers/${node.slug}/`}
                    className="more-details link--with-arrow"
                  >
                    More Info
                  </Link>
                </div>
              ))}

              {/* Email Card */}
              <div className="careers-email-card">
                <h5>Don’t see an opening that fits?</h5>
                <p>Introduce yourself at </p>
                <a
                  className="button--primary button--primary--lowercase"
                  href="mailto:jobs@traefik.io"
                >
                  jobs@traefik.io
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default CareersPage

const scrollTo = (id: string) => {
  let position = document.querySelector(id)?.offsetTop || 0
  window.scroll({
    top: position,
    left: 0,
    behavior: "smooth",
  })
}

export const query = graphql`
  {
    map_image: file(relativePath: { eq: "careers/map/map.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, width: 1250)
      }
    }
    job_posts: allGhostPost(
      filter: { primary_tag: { slug: { eq: "careers" } } }
      sort: { order: DESC, fields: [published_at] }
    ) {
      edges {
        node {
          ...GhostPostCardFields
          childHtmlRehype {
            html
          }
        }
      }
    }
    current_page: ghostPage(slug: { eq: "careers" }) {
      ...PageMetaFields
    }
  }
`
